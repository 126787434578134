import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Separator } from 'app/components/Common/Separator'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  images?: ImageProps[]
  subtitle?: string
  title?: string
  variant?: Variant
}

export const Intro = memo(function Intro({
  description,
  images,
  subtitle,
  title,
  variant = 'default',
}: Props) {
  return (
    <Container variant={variant}>
      <Separator />
      <Wrap>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        {subtitle ? (
          <FadeInUp>
            <Subtitle>{subtitle}</Subtitle>
          </FadeInUp>
        ) : null}
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
      </Wrap>
      {images ? (
        <ImageGroup dial={5} row>
          {images.map((item, index) => (
            <Background key={index}>
              <LazyLoadComponent>
                <Image {...item} />
              </LazyLoadComponent>
            </Background>
          ))}
        </ImageGroup>
      ) : null}
    </Container>
  )
})

const Container = styled.section<ContainerProps>`
  margin: 8.75rem auto 15rem;

  ${({ variant }) => {
    switch (variant) {
      case 'filters':
        return css`
          margin-top: 15rem;
        `
    }
  }}

  @media (max-width: 1023px) {
    margin-top: 8.5rem;
    margin-bottom: 10rem;
  }

  @media (max-width: 767px) {
    margin-top: 5.625rem;
    margin-bottom: 5.625rem;
  }
`

const Wrap = styled.div`
  max-width: 41.875rem;
  margin: auto;
  text-align: center;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0 1.25rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.375rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.1625rem;
  line-height: 1.125rem;
  margin-top: 1.25rem;
  text-transform: uppercase;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  letter-spacing: -0.0313rem;
  line-height: 2.25rem;
  margin-top: 2.5rem;

  @media (max-width: 1023px) {
    font-size: 1rem;
    line-height: 1.75rem;
    margin-top: 1.875rem;
  }
`

const ImageGroup = styled(FlexBox)`
  max-width: 67.25rem;
  margin: 9.75rem auto 0;

  @media (max-width: 1023px) {
    margin-top: 7.5rem;
  }
`

const Background = styled.div`
  width: 25%;
  position: relative;
  padding-bottom: 36%;
  margin-right: 1.5rem;

  &:last-of-type {
    margin-right: 0;
  }
  &:first-of-type,
  &:last-of-type {
    transform: translateY(-3.5rem);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    margin-right: 0.75rem;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'filters'
